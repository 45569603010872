.detailed-table-inner-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.detailed-table-modal-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    position: absolute;
}

.detailed-table-modal-layout-alt {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
}

.detailed-table-inner-header {
    width: 100%;
    height: 10%;
    display: flex;
    text-align: center;
}

.detailed-table-inner-main {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.detailed-table-header {
    width: 100%;
    height: 100%;
    border-bottom: 2px solid black;
    border-radius: 10px 10px 0 0;
    background-color: lightblue;
    display: flex;
    font-weight: bold;
    font-size: small;
    flex-direction: row;
    align-items: center;
}

.detailed-table-item {
    width: 100%;
    height: 10%;
    font-size: small;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid black;
}

.detailed-symptoms-id {
    width: 8%;
}

.detailed-symptoms-group-id {
    width: 10%;
    border-left: 1px solid black;
}

.detailed-symptoms-name {
    width: 25%;
    border-left: 1px solid black;
}

.detailed-symptoms-name-rus {
    width: 25%;
    border-left: 1px solid black;
}

.detailed-symptoms-priority {
    width: 10%;
    border-left: 1px solid black;
}

.detailed-symptoms-category {
    width: 12%;
    border-left: 1px solid black;
}

.detailed-actions {
    width: 10%;
    border-left: 1px solid black;
}

.detailed-actions-item {
    width: 10%;
    border-left: 1px solid black;
    display: flex;
    flex-direction: row;
}

.detailed-actions-item-edit-button {
    width: 50%;
    height: 100%;
    background-color: white;
    border: none;
}

.detailed-actions-item-edit-button:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.detailed-actions-item-edit-button:active {
    background-color: cornflowerblue;
    cursor: pointer;
}

.detailed-actions-edit-icon {
    height: 50%;
    max-height: 23px;
    width: 50%;
    max-width: 23px;
    background-size: cover;
}

.detailed-actions-item-delete-button {
    width: 50%;
    height: 100%;
    border: none;
    border-left: 1px solid black;
    background-color: white;
}

.detailed-actions-item-delete-button:hover {
    background-color: palevioletred;
    cursor: pointer;
}

.detailed-actions-item-delete-button:active {
    background-color: red;
    cursor: pointer;
}

.detailed-actions-delete-icon {
    height: 50%;
    max-height: 23px;
    width: 50%;
    max-width: 23px;
    background-size: cover;
}

.detailed-table-inner-footer {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    text-align: center;
}

.detailed-table-create {
    margin-left:auto;
    margin-right: 20px;
    height: 30px;
    width: 25%;
    font-weight: bold;
    font-size: small;
    border: 1px solid black;
    border-radius: 10px;
}

.detailed-table-create-button {
    width: 100%;
    height: 100%;
    background-color: white;
    font-weight: bold;
    border-radius: 10px;
    border: none;
}

.detailed-table-create-button:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.detailed-table-create-button:active {
    background-color: blue;
    cursor: pointer;
    color: white;
}