.tags-modal-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: 30px;
    margin-left: 25%;
    width: 50%;
    margin-right: 25%;
    border-radius: 10px;
    background-color: white;
    border: 1px solid black;
    height: 80%;
    max-height: 80%;
}

.tags-modal-wrapper-alt {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
}

.tags-modal-header {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px 10px 0 0;
    border-bottom: 2px solid black;
    background-color: lightblue;
}

.tags-modal-main {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    text-align: center;
    overflow-y: scroll;
}

.tags-modal-button-wrapper {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    border-top: 1px solid black;
}

.tags-modal-name {
    margin-left: 30px;
    width: 80%;
    font-weight: bold;
    text-align: left;
}

.tags-modal-close-item {
    margin-right: 5px;
    align-items: center;
    background-color: lightblue;
    cursor: pointer;
}

.tags-modal-close-button {
    height: 30px;
    width: 30px;
    padding: 0;
    align-items: center;
    text-align: center;
    border: none;
    background-color: lightblue;
}

.tags-modal-close-button:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.tags-modal-close-button:active {
    background-color: blue;
    cursor: pointer;
}

.tags-modal-close-icon {
    height: 15px;
    width: 15px;
}

.tags-modal-subheader {
    display: flex;
    height: 10%;
    align-items: center;
    border-bottom: 2px solid black;
    font-size: small;
}

.tags-modal-button-block {
    display: flex;
    height: 50%;
    width: 40%;
    font-weight: bold;
    font-size: small;
    border: 1px solid black;
    border-radius: 10px;
    margin: auto;
}

.tags-modal-button:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.tags-modal-button:active {
    background-color: blue;
    color: white;
    cursor: pointer;
}

.tags-modal-button {
    width: 100%;
    height: 100%;
    background-color: white;
    font-weight: bold;
    border-radius: 10px;
    border: none;
}

.tags-modal-content {
    width: 100%;
    height: 90%;
}

.tags-item {
    width: 100%;
    height: 15%;
    font-size: small;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid black;
}

.tags-id {
    width: 20%;
}

.tags-name {
    width: 60%;
    border-left: 1px solid black;
}

.tags-actions {
    width: 20%;
    border-left: 1px solid black;
}

.tags-id-subheader {
    width: 20%;
}

.tags-name-subheader {
    width: 60%;
    border-left: 1px solid black;
}

.tags-actions-subheader {
    width: 20%;
    border-left: 1px solid black;
}

.tags-actions-item {
    width: 20%;
    border-left: 1px solid black;
}

.tags-actions-item-edit-button {
    width: 50%;
    height: 100%;
    background-color: white;
    border: none;
}

.tags-actions-item-edit-button:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.tags-actions-item-edit-button:active {
    background-color: cornflowerblue;
    cursor: pointer;
}

.tags-actions-edit-icon {
    height: 50%;
    max-height: 23px;
    width: 50%;
    max-width: 23px;
    background-size: cover;
}

.tags-actions-item-delete-button {
    width: 50%;
    height: 100%;
    border: none;
    border-left: 1px solid black;
    background-color: white;
}

.tags-actions-item-delete-button:hover {
    background-color: palevioletred;
    cursor: pointer;
}

.tags-actions-item-delete-button:active {
    background-color: red;
    cursor: pointer;
}

.tags-actions-delete-icon {
    height: 50%;
    max-height: 23px;
    width: 50%;
    max-width: 23px;
    background-size: cover;
}