.modules-modal-inner-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    border-radius: 10px;
    align-items: center;
}

.modules-table-modal-layout {
    height: 83%;
    width: 83%;
    margin: auto;
    background-color: white;
    border-radius: 10px;
}

.modules-table-outer-header {
    height: 10%;
    width: 100%;
    background-color: lightblue;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px 10px 0 0;
    border-bottom: 2px solid black;
    position: sticky;
}

.modules-modal-back-button {
    height: 30px;
    width: 10%;
    max-width: 50px;
    padding: 0;
    margin-left: 10px;
    align-items: center;
    text-align: center;
    border: none;
    background-color: lightblue;
}

.modules-modal-back-button:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.modules-modal-back-button:active {
    background-color: blue;
    cursor: pointer;
}

.modules-modal-back-icon {
    height: 20px;
    width: 20px;
}

.modules-training-name {
    width: 63%;
    text-align: left;
    font-weight: bold;
}

.modules-table-inner-header {
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: row;
    background-color: lightblue;
    align-items: center;
    text-align: center;
    font-size: small;
    border-bottom: 1px solid black;
}

.modules-exercise-header {
    width: 30%;
}

.modules-symptoms-header {
    width: 30%;
    border-left: 1px solid black;
}

.modules-alternative-exercise-header {
    width: 30%;
    border-left: 1px solid black;
}

.modules-actions-header {
    width: 12%;
    border-left: 1px solid black;
}

.modules-table-inner-main {
    height: 70%;
    overflow-y: scroll;
}

.modules-table-item {
    width: 100%;
    height: 14%;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    font-size: small;
    border-bottom: 1px solid black;
}

.modules-exercise-item {
    width: 30%;
}

.modules-symptoms-item {
    width: 30%;
    border-left: 1px solid black;
}

.modules-alternative-exercise-item {
    width: 30%;
    border-left: 1px solid black;
}

.modules-actions-item {
    width: 10%;
    border-left: 1px solid black;
    display: flex;
    flex-direction: row;
}

.modules-actions-item-edit-button {
    width: 50%;
    height: 100%;
    background-color: white;
    border: none;
}

.modules-actions-item-edit-button:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.modules-actions-item-edit-button:active {
    background-color: cornflowerblue;
    cursor: pointer;
}

.modules-actions-edit-icon {
    height: 50%;
    max-height: 23px;
    width: 50%;
    max-width: 23px;
    background-size: cover;
}

.modules-actions-item-delete-button {
    height: 50%;
    width: 50%;
    border: none;
    border-left: 1px solid black;
    background-color: white;
}

.modules-actions-item-delete-button:hover {
    background-color: palevioletred;
    cursor: pointer;
}

.modules-actions-item-delete-button:active {
    background-color: red;
    cursor: pointer;
}

.modules-actions-delete-icon {
    height: 50%;
    max-height: 23px;
    width: 50%;
    max-width: 23px;
    background-size: cover;
}

.modules-table-inner-footer {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    text-align: center;
    border-top: 1px solid black;
}

.modules-table-create {
    margin-left:auto;
    margin-right: 20px;
    height: 30px;
    width: 25%;
    font-weight: bold;
    font-size: small;
    border: 1px solid black;
    border-radius: 10px;
}

.modules-table-create-button {
    width: 100%;
    height: 100%;
    background-color: white;
    font-weight: bold;
    border-radius: 10px;
    border: none;
}

.modules-table-create-button:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.modules-table-create-button:active {
    background-color: blue;
    cursor: pointer;
    color: white;
}