.notes-table-inner-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.notes-table-modal-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    position: absolute;
}

.notes-table-modal-layout-alt {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
}

.notes-table-inner-header {
    width: 100%;
    height: 10%;
    display: flex;
    text-align: center;
}

.notes-table-inner-main {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.notes-table-header {
    width: 100%;
    height: 100%;
    border-bottom: 2px solid black;
    border-radius: 10px 10px 0 0;
    background-color: lightblue;
    display: flex;
    font-weight: bold;
    font-size: small;
    flex-direction: row;
    align-items: center;
}

.notes-table-item {
    width: 100%;
    height: 10%;
    font-size: small;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid black;
}

.notes-id {
    width: 10%;
}

.notes-user-id {
    width: 20%;
    border-left: 1px solid black;
}

.notes-date-of-fill {
    width: 30%;
    border-left: 1px solid black;
}

.notes-symptoms-detailed {
    width: 30%;
    border-left: 1px solid black;
}

.notes-priority {
    width: 10%;
    border-left: 1px solid black;
}

.notes-table-inner-footer {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    text-align: center;
}