.parts-modal-layout {
    height: 83%;
    width: 83%;
    margin: auto;
    background-color: white;
    border-radius: 10px;
}

.parts-modal-outer-header {
    height: 10%;
    width: 100%;
    background-color: lightblue;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px 10px 0 0;
    border-bottom: 2px solid black;
    position: sticky;
}

.parts-modal-back-button {
    height: 30px;
    width: 10%;
    max-width: 50px;
    padding: 0;
    margin-left: 10px;
    align-items: center;
    text-align: center;
    border: none;
    background-color: lightblue;
}

.parts-modal-back-button:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.parts-modal-back-button:active {
    background-color: blue;
    cursor: pointer;
}

.parts-modal-back-icon {
    height: 20px;
    width: 20px;
}

.parts-modal-window-name {
    width: 63%;
    text-align: left;
    font-weight: bold;
}

.parts-modal-main {
    display: flex;
    flex-direction: row;
    height: 78%;
}

.parts-modal-main-container-outer{
    width: 30%;
    height: 95%;
    margin: 10px 10px 10px 20px;
    border-radius: 10px;
    border: 1px solid black;
}

.parts-modal-main-container-label {
    font-size: medium;
    font-weight: bold;
    margin-left: 20px;
    text-align: left;
}

.parts-modal-main-container-inner{
    width: 95%;
    height: 88%;
    margin: 10px 5px 0 5px;
    border: 1px solid black;
    border-radius: 10px;
}

.parts-modal-exercises-container {
    width: 95%;
    height: 48%;
    margin-bottom: 10px;
    border: 1px solid black;
    border-radius: 10px;
    padding: 5px 10px;
}

.parts-modal-select {
    width: 100%;
    height: 30px;
    margin-bottom: 5px;
}

.tag-label {
    font-size: small;
    margin-left: 5px;
    text-align: left;
}

.parts-modal-exercises-legend {
    font-size: small;
    font-weight: bold;
    text-align: left;
}

.modules-chosen {
    width: 100%;
    height: 100%;
    border: 1px solid black;
    overflow-y: scroll;
}

.modules-chosen-symptom{
    height: 20%;
    border-bottom: 1px solid black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: small;
    align-items: center;
}

.modules-chosen-symptom-item {
    padding-left: 10px;
    text-align: left;
    width: 60%;
}

.modules-chosen-symptom-delete {
    border-left: 1px solid black;
    height: 100%;
    width: 20%;
    display: flex;
    justify-content: center;
}

.modules-chosen-symptom-delete:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.modules-chosen-symptom-delete:active {
    background-color: blue;
    cursor: pointer;
}

.modules-chosen-symptom-delete-icon {
    margin: auto;
    width: 50%;
    max-width: 10px;
    height: 50%;
    max-height: 10px;
}

.parts-modal-footer {
    width: 100%;
    height: 12%;
    justify-content: center;
    text-align: center;
}

.modules-confirm {
    height: 30px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid black;
    background-color: white;
    font-weight: bold;
}

.modules-confirm:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.modules-confirm:active {
    background-color: blue;
    cursor: pointer;
    color: white;
}
