.table-list-wrapper {
    width: 200px;
    padding: 20px 20px;
    margin-top: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: small;
    font-weight: bold;
}

.table-list-button {
    width: 170px;
    height: 40px;
    margin-bottom: 10px;
    border: 2px solid black;
    border-radius: 10px;
    display: flex;
    text-align: center;
    align-items: center;
    cursor: pointer;
}

.table-list-button-alt {
    width: 170px;
    height: 40px;
    margin-bottom: 10px;
    border: 2px solid black;
    border-radius: 10px;
    display: flex;
    text-align: center;
    align-items: center;
    background-color: blue;
    color: white;
    cursor: pointer;
}

.table-list-button-text{
    margin-left: auto;
    margin-right: auto;
}

.table-list-button:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.table-list-button-alt:hover {
    background-color: deepskyblue;
    color: black;
    cursor: pointer;
}