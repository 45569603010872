* {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.page-layout {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
}