@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap');

* {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.login-form {
    margin: 40px 0;
    padding: 10px 20px;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid black;
    border-radius: 10px;
}

.login-form-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    padding: 20px 0;
}

.login-form-label {
    text-align: left;
    margin-block-end: 0;
    margin-block-start: 0;
    font-weight: normal;
}

.login-form-error{
    color: red;
    display: block;
    margin-top: 20px;
}

.login-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.login-form-button {
    font-size: large;
    border: transparent;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    background-color: lightblue;
    font-weight: normal;
}

.login-form-button:hover {
    background-color: deepskyblue;
    color: black;
}

.login-form-button:active {
    background-color: blue;
    color: white;
}

.login-form-bold {
    padding: 6px;
}

.login-form-copyright {
    margin-top: 20px;
    font-size: small;
}

.login-form-password-wrapper {
    position: relative;
}

.login-form-password-hide {
    height: 20px;
    width: 20px;
    float: right;
    margin-left: -30px;
    margin-top: 4px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
}

.login-form-password-show {
    height: 20px;
    width: 20px;
    float: right;
    margin-left: -30px;
    margin-top: 4px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
}

input {
    width: 300px;
    height: 30px;
}
