.main-theme-wrapper {
    width: 80%;
    height: 87%;
    margin-top: 10px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 10px;
}

.main-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    border-radius: 10px;
    background-color: white;
    opacity: 0.5;
}