.user-table-inner-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.user-table-modal-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    position: absolute;
}

.user-table-modal-layout-alt {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
}

.user-table-inner-header {
    width: 100%;
    height: 10%;
    display: flex;
    text-align: center;
}

.user-table-inner-main {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.user-table-header {
    width: 100%;
    height: 100%;
    border-bottom: 2px solid black;
    border-radius: 10px 10px 0 0;
    background-color: lightblue;
    display: flex;
    font-weight: bold;
    font-size: small;
    flex-direction: row;
    align-items: center;
}

.user-table-item {
    width: 100%;
    height: 10%;
    font-size: x-small;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid black;
}

.user-id {
    width: 4%;
}

.user-name {
    width: 17%;
    border-left: 1px solid black;
}

.user-age {
    width: 7%;
    border-left: 1px solid black;
}

.user-sex {
    width: 5%;
    border-left: 1px solid black;
}

.user-category {
    width: 8%;
    border-left: 1px solid black;
}

.user-pregnancy-count {
    width: 8%;
    border-left: 1px solid black;
}

.user-pregnancy-week {
    width: 8%;
    border-left: 1px solid black;
}

.user-birth-date {
    width: 8%;
    border-left: 1px solid black;
}

.user-weight-before {
    width: 6%;
    border-left: 1px solid black;
}

.user-weight-now {
    width: 6%;
    border-left: 1px solid black;
}

.user-height {
    width: 5%;
    border-left: 1px solid black;
}

.user-email {
    width: 17%;
    border-left: 1px solid black;
}

.user-amount {
    margin-right: 20px;
    font-size: small;
}

.user-table-inner-footer {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}

.user-amount-span {
    font-weight: bold;
    font-size: small;
}