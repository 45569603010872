.trainings-modal-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: 30px;
    margin-left: 30%;
    width: 40%;
    margin-right: 30%;
    border-radius: 10px;
    background-color: white;
    border: 1px solid black;
}

.trainings-modal-header {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px 10px 0 0;
    background-color: lightblue;
}

.trainings-modal-name {
    margin-left: 30px;
    width: 80%;
    font-weight: bold;
    text-align: left;
}

.trainings-modal-close-item {
    margin-right: 5px;
    align-items: center;
    background-color: lightblue;
    cursor: pointer;
}

.trainings-modal-close-button {
    height: 30px;
    width: 30px;
    padding: 0;
    align-items: center;
    text-align: center;
    border: none;
    background-color: lightblue;
}

.trainings-modal-close-button:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.trainings-modal-close-button:active {
    background-color: blue;
    cursor: pointer;
}

.trainings-modal-close-icon {
    height: 15px;
    width: 15px;
}

.trainings-handler {
    margin-bottom: 20px;
}

.trainings-modal-main {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: small;
    justify-content: space-between;
}

.trainings-modal-container {
    margin-left: 10px;
    padding: 5px 10px;
    border: 1px solid black;
    border-radius: 10px;
    height: 90%;
}

.trainings-modal-characteristics {
    width: 100%;
    height: 100%;
    font-size: small;
    margin: 10px 20px 10px 20px;
}

.trainings-label {
    margin-left: 5px;
    text-align: left;
}

.trainings-modal-footer {
    margin-bottom: 20px;
}

.trainings-modal-confirm {
    height: 30px;
    border-radius: 5px;
    border: 1px solid black;
    background-color: white;
    font-weight: bold;
}

.trainings-modal-confirm:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.trainings-modal-confirm:active {
    background-color: blue;
    cursor: pointer;
    color: white;
}

.trainings-input {
    width: 100%;
    font-weight: bold;
}

.trainings-select {
    width: 100%;
    height: 30px;
}