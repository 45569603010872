.training-routine-table-inner-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.training-routine-table-modal-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    position: absolute;
}

.training-routine-table-modal-layout-alt {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
}

.training-routine-table-inner-header {
    width: 100%;
    height: 10%;
    display: flex;
    text-align: center;
}

.training-routine-table-inner-main {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.training-routine-table-header {
    width: 100%;
    height: 100%;
    border-bottom: 2px solid black;
    border-radius: 10px 10px 0 0;
    background-color: lightblue;
    display: flex;
    font-weight: bold;
    font-size: small;
    flex-direction: row;
    align-items: center;
}

.training-routine-table-item {
    width: 100%;
    height: 10%;
    font-size: small;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid black;
}

.training-routine-id {
    width: 13%;
}

.training-routine-user-id {
    width: 18%;
    border-left: 1px solid black;
}

.training-routine-plan-id {
    width: 18%;
    border-left: 1px solid black;
}

.training-routine-program-id {
    width: 18%;
    border-left: 1px solid black;
}

.training-routine-training-id {
    width: 18%;
    border-left: 1px solid black;
}

.training-routine-finish-date {
    width: 15%;
    border-left: 1px solid black;
}

.training-routine-table-inner-footer {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
}