.header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    vertical-align: middle;
    border-bottom: 2px solid black;
}

.header-main {
    display: flex;
}

.header-logo {
    margin-left: 40px;
    margin-right: 10px;
    display: flex;
    text-align: center;
    align-items: center;
    cursor: pointer;
}

.header-menu {
    margin-left: 50px;
    display: flex;
    flex-direction: row;
    vertical-align: middle;
}

.header-button-item {
    display: flex;
    text-align: center;
    align-items: center;
    width: 120px;
    height: 40px;
    margin: 10px 10px;
    border: 2px solid black;
    border-radius: 10px;
}

.header-button-item-alt {
    display: flex;
    text-align: center;
    align-items: center;
    width: 120px;
    height: 40px;
    margin: 10px 10px;
    padding: 0 5px;
    border: 2px solid black;
    border-radius: 10px;
    background-color: blue;
    color: white;
}

.header-button-item:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.header-button-item-alt:hover {
    background-color: deepskyblue;
    color: black;
    cursor: pointer;
}

.header-item-name {
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    font-size: small;
}
span {
    font-weight: bold;
    font-size: x-large;
}

