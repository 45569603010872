.credentials-table-inner-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.credentials-table-modal-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    position: absolute;
}

.credentials-table-modal-layout-alt {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
}

.credentials-table-inner-header {
    width: 100%;
    height: 10%;
    display: flex;
    text-align: center;
}

.credentials-table-inner-main {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.credentials-table-header {
    width: 100%;
    height: 100%;
    border-bottom: 2px solid black;
    border-radius: 10px 10px 0 0;
    background-color: lightblue;
    display: flex;
    font-weight: bold;
    font-size: small;
    flex-direction: row;
    align-items: center;
}

.credentials-table-item {
    width: 100%;
    height: 10%;
    font-size: small;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid black;
}

.credentials-id {
    width: 15%;
}

.credentials-email {
    width: 35%;
    border-left: 1px solid black;
}

.credentials-role {
    width: 25%;
    border-left: 1px solid black;
}

.credentials-confirmed {
    width: 25%;
    border-left: 1px solid black;
}

.credentials-table-inner-footer {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    text-align: center;
}

.credentials-create {
    margin-left:auto;
    margin-right: 20px;
    height: 30px;
    width: 35%;
    font-weight: bold;
    font-size: small;
    border: 1px solid black;
    border-radius: 10px;
}

.credentials-create-button {
    width: 100%;
    height: 100%;
    background-color: white;
    font-weight: bold;
    border-radius: 10px;
    border: none;
}

.credentials-create-button:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.credentials-create-button:active {
    background-color: blue;
    cursor: pointer;
    color: white;
}