@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap');

#root {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

