.plans-modal-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: 30px;
    margin-left: 20%;
    width: 60%;
    height: 80%;
    margin-right: 20%;
    border-radius: 10px;
    background-color: white;
    border: 1px solid black;
}

.plans-modal-header {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px 10px 0 0;
    background-color: lightblue;
}

.plans-modal-name {
    margin-left: 30px;
    width: 80%;
    font-weight: bold;
    text-align: left;
}

.plans-modal-close-item {
    margin-right: 5px;
    align-items: center;
    background-color: lightblue;
    cursor: pointer;
}

.plans-modal-close-button {
    height: 30px;
    width: 30px;
    padding: 0;
    align-items: center;
    text-align: center;
    border: none;
    background-color: lightblue;
}

.plans-modal-close-button:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.plans-modal-close-button:active {
    background-color: blue;
    cursor: pointer;
}

.plans-modal-close-icon {
    height: 15px;
    width: 15px;
}

.plans-handler {
    margin-bottom: 20px;
}

.plans-modal-main {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
    font-size: small;
    justify-content: space-between;
}

.plans-container-label {
    font-size: medium;
    font-weight: bold;
    margin-left: 15px;
    text-align: left;
    height: 10%;
}

.plans-modal-container {
    margin-left: 10px;
    padding: 5px 10px;
    border: 1px solid black;
    border-radius: 10px;
    height: 90%;
}

.plans-modal-characteristics {
    width: 47%;
    font-size: small;
    margin: 10px 0 10px 10px;
}

.plans-modal-programs {
    width: 47%;
    font-size: small;
    margin: 10px;
}

.plans-program-container {
    width: 100%;
    height: 40%;
    border: 1px solid black;
    margin-bottom: 10px;
    overflow-y: scroll;
}

.plans-chosen-program{
    height: 20%;
    border-bottom: 1px solid black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.plans-chosen-program-item {
    padding-left: 10px;
    text-align: left;
    width: 60%;
}

.plans-program-delete {
    border-left: 1px solid black;
    width: 20%
}

.plans-program-delete:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.plans-program-delete:active {
    background-color: blue;
    cursor: pointer;
}

.plans-program-delete-icon {
    width: 5px;
    height: 5px;
}

.plans-program-list {
    height: 10%;
    width: 100%;
    resize: none;
}

.plans-label {
    margin-left: 5px;
    text-align: left;
}

.plans-modal-footer {
    margin-bottom: 20px;
}

.plans-modal-confirm {
    height: 30px;
    border-radius: 5px;
    border: 1px solid black;
    background-color: white;
    font-weight: bold;
}

.plans-modal-confirm:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.plans-modal-confirm:active {
    background-color: blue;
    cursor: pointer;
    color: white;
}

.plans-input {
    width: 100%;
    font-weight: bold;
}

.plans-select {
    width: 100%;
    height: 30px;
}