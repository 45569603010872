.credentials-modal-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: 30px;
    margin-left: 25%;
    margin-right: 25%;
    border-radius: 10px;
    background-color: white;
    border: 1px solid black;
}

.credentials-modal-header {
    width: 100%;
    height: 40px;
    display: flex;
    font-size: small;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px 10px 0 0;
    background-color: lightblue;
}

.credentials-modal-name {
    margin-left: 30px;
    width: 80%;
    font-weight: bold;
    text-align: left;
}

.credentials-modal-close-item {
    margin-right: 5px;
    align-items: center;
    background-color: lightblue;
    cursor: pointer;
}

.credentials-modal-close-button {
    height: 30px;
    width: 30px;
    padding: 0;
    align-items: center;
    text-align: center;
    border: none;
    background-color: lightblue;
}

.credentials-modal-close-button:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.credentials-modal-close-button:active {
    background-color: blue;
    cursor: pointer;
}

.credentials-modal-close-icon {
    height: 15px;
    width: 15px;
}

.credentials-modal-main {
    margin: 15px 50px;
    font-size: small;
}

.credentials-email-handler {
    margin-bottom: 15px;
}

.credentials-email-label {
    margin-left: 5px;
    text-align: left;
}

.credentials-password-label {
    margin-left: 5px;
    text-align: left;
}

.credentials-password-handler {
    margin-bottom: 15px;
}

.credentials-modal-footer {
    margin-bottom: 20px;
}

.credentials-modal-confirm {
    height: 30px;
    border-radius: 5px;
    border: 1px solid black;
    background-color: white;
    font-weight: bold;
}

.credentials-modal-confirm:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.credentials-modal-confirm:active {
    background-color: blue;
    cursor: pointer;
    color: white;
}

.credentials-modal-input {
    font-weight: bold;
}

.credentials-role-label {
    margin-left: 5px;
    text-align: left;
}

.role-select {
    width: 100%;
    height: 30px;
}