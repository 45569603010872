.pagination-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 22px 23px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.pagination-simple {
    text-decoration: none;
    justify-content: center;
    display: flex;
    min-width: 12px;
    left: 1px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    padding: 0 3px;
}

li {
    list-style-type: none;
}

.pagination-current {
    text-decoration: none;
    background-color: #0087E9;
    justify-content: center;
    display: flex;
    min-width: 12px;
    left: 1px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    padding: 0 3px;
}

.pagination-simple:hover {
    background-color: #E1E1E1;
    color: #000000;
    cursor: pointer;
}

.pagination-current:hover{
    background-color: #0000ff;
    color: #ffffff;
}