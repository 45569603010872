.search-item {
    width: 35%;
    margin-right: 20px;
}

.search-input {
    width: 20%;
    margin-right: 15px;
    height: 25px;
}

.search-label {
    font-size: small;
    margin-right: 15px;
}

.search-button {
    width: 20%;
    background-color: white;
    font-weight: bold;
    border-radius: 10px;
    border: 1px solid black;
    padding: 5px 10px;
}

.search-button:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.search-button:active {
    background-color: blue;
    cursor: pointer;
    color: white;
}

.search-cancel-button {
    width: 20%;
    background-color: white;
    font-weight: bold;
    border-radius: 10px;
    border: 1px solid black;
    padding: 5px 10px;
}

.search-cancel-button-icon {
    width: 10px;
    height: 10px;
}

.search-cancel-button:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.search-cancel-button:active {
    background-color: blue;
    cursor: pointer;
    color: white;
}