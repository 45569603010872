.exercises-table-inner-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.exercises-table-modal-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    position: absolute;
}

.exercises-table-modal-layout-alt {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
}

.exercises-table-inner-header {
    width: 100%;
    height: 10%;
    display: flex;
    text-align: center;
}

.exercises-table-inner-main {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.exercises-table-header {
    width: 100%;
    height: 100%;
    border-bottom: 2px solid black;
    border-radius: 10px 10px 0 0;
    background-color: lightblue;
    display: flex;
    font-weight: bold;
    font-size: small;
    flex-direction: row;
    align-items: center;
}

.exercises-table-item {
    width: 100%;
    height: 10%;
    font-size: small;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid black;
    overflow: hidden;
}

.exercises-id-header {
    width: 5%;
}

.exercises-tag-header {
    width: 10%;
    border-left: 1px solid black;
}

.exercises-preview-header {
    width: 15%;
    border-left: 1px solid black;
}

.exercises-title-header {
    width: 15%;
    border-left: 1px solid black;
}

.exercises-video-link-header {
    width: 15%;
    border-left: 1px solid black;
}

.exercises-duration-header {
    width: 5%;
    border-left: 1px solid black;
}

.exercises-description-header {
    width: 25%;
    border-left: 1px solid black;
    font-size: small;
    word-break: break-all;
}

.exercises-id {
    width: 5%;
    font-size: smaller;
}

.exercises-tag {
    width: 10%;
    border-left: 1px solid black;
}

.exercises-preview {
    width: 15%;
    border-left: 1px solid black;
    font-size: smaller;
    display: flex;
    flex-direction: row;
}

.exercises-preview-link {
    padding-top: 3px;
    width: 80%;
    font-size: small;
}

.exercises-preview-copy-item {
    width: 20%;
}

.preview-link-text {
    color: black;
    font-style: italic;
}

.exercises-preview-copy-button {
    width: 100%;
    height: 100%;
    background-color: white;
    border: none;
}

.exercises-preview-copy-button:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.exercises-preview-copy-button:active {
    background-color: cornflowerblue;
    cursor: pointer;
}

.exercises-preview-copy-icon {
    height: 70%;
    max-height: 23px;
    width: 60%;
    max-width: 23px;
    background-size: cover;
}

.exercises-title {
    width: 15%;
    border-left: 1px solid black;
    font-size: smaller;
}

.exercises-description {
    width: 25%;
    border-left: 1px solid black;
    font-size: smaller;
    word-break: break-all;
}

.exercises-video-link {
    width: 15%;
    border-left: 1px solid black;
    display: flex;
    flex-direction: row;
    font-size: small;
}

.exercises-video-text-link {
    padding-top: 3px;
    width: 80%;
    font-size: small;
}

.exercises-video-copy-item {
    width: 20%;
}

.video-link-text {
    color: black;
    font-style: italic;
}

.exercises-video-copy-button {
    width: 100%;
    height: 100%;
    background-color: white;
    border: none;
}

.exercises-video-copy-button:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.exercises-video-copy-button:active {
    background-color: cornflowerblue;
    cursor: pointer;
}

.exercises-video-copy-icon {
    height: 70%;
    max-height: 23px;
    width: 60%;
    max-width: 23px;
    background-size: cover;
}

.exercises-duration {
    width: 5%;
    border-left: 1px solid black;
    font-size: smaller;
}

.exercises-actions {
    width: 10%;
    border-left: 1px solid black;
}

.exercises-actions-item {
    width: 10%;
    border-left: 1px solid black;
    display: flex;
    flex-direction: row;
}

.exercises-actions-item-edit-button {
    width: 50%;
    height: 100%;
    background-color: white;
    border: none;
}

.exercises-actions-item-edit-button:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.exercises-actions-item-edit-button:active {
    background-color: cornflowerblue;
    cursor: pointer;
}

.exercises-actions-edit-icon {
    height: 50%;
    max-height: 23px;
    width: 50%;
    max-width: 23px;
    background-size: cover;
}

.exercises-actions-item-delete-button {
    height: 50%;
    width: 50%;
    border: none;
    border-left: 1px solid black;
    background-color: white;
}

.exercises-actions-item-delete-button:hover {
    background-color: palevioletred;
    cursor: pointer;
}

.exercises-actions-item-delete-button:active {
    background-color: red;
    cursor: pointer;
}

.exercises-actions-delete-icon {
    height: 50%;
    max-height: 23px;
    width: 50%;
    max-width: 23px;
    background-size: cover;
}

.exercises-table-inner-footer {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
}

.exercises-buttons {
    width: 50%;
    display: flex;
    justify-content: right;
}

.exercises-button-block {
    margin-right: 20px;
    height: 30px;
    width: 40%;
    font-weight: bold;
    font-size: small;
    border: 1px solid black;
    border-radius: 10px;
}

.exercises-button {
    width: 100%;
    height: 100%;
    background-color: white;
    font-weight: bold;
    border-radius: 10px;
    border: none;
}

.exercises-button:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.exercises-button:active {
    background-color: blue;
    cursor: pointer;
    color: white;
}