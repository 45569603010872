@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap');

.profile-wrapper {
    display: flex;
    flex-direction: row;
    float: right;
    align-items: center;
    height: 100%;
}

.profile-name {
    padding: 3px 0;
    display: flex;
    float: left;
    vertical-align: middle;
    text-align: center;
    align-items: center;
    font-size: small;
    font-weight: bold;
}

.profile-logout-button-wrapper {
    display: inline-block;
    margin-left: 50px;
    margin-right: 10px;
}

.profile-logout-button {
    width: 70px;
    height: 30px;
    padding: 3px;
    border: 1px solid black;
    border-radius: 5px;
    font-weight: bold;
    font-size: small;
    cursor: pointer;
    background-color: lightblue;

}

.profile-logout-button:hover {
    background-color: deepskyblue;
    color: black;
}

.profile-logout-button:active {
    background-color: blue;
    color: white;
}